var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('nav-drawer',[_c('v-list',[_c('v-list-item',{attrs:{"exact":"","to":{
          name: 'sample-landing-page',
          params: { sampleId: _vm.$route.params.sampleId },
        },"replace":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"white-space":"pre-line","hyphens":"auto"}},[_vm._v(" Sample Details ")])],1)],1)],1),_c('v-btn',{staticClass:"mx-4 mt-5 black--text",attrs:{"rounded":"","color":"secondary","to":{
        name: 'create-coa-sample',
        params: { sampleId: _vm.$route.params.sampleId },
      }}},[_vm._v("+ NEW COMBINED COA")]),_c('v-list',{staticClass:"mt-5"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" Combined COAs ")])],1)],1),(_vm.coas.length == 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("(No Certificates created)")])],1)],1):_vm._e(),_vm._l((_vm.coas),function(coa){return _c('v-list-item',{key:coa.id,attrs:{"to":{
          name: 'coa-sample',
          params: { coaId: coa.id, sampleId: _vm.$route.params.sampleId },
        },"replace":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"white-space":"pre-line","hyphens":"auto"}},[_vm._v(" "+_vm._s(coa.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.formatDate(coa.date_published || coa.creation_date))+" ")])],1)],1)})],2)],1),_c('nav-bar',{attrs:{"show-back":"","breadcrumbs":_vm.breadcrumbs}}),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }