



































































import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/NavBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import { formatDate } from "@/date-utils";
import { api } from "@/api";
import { ICOA } from "@/interfaces";
import { callApi } from "@/store/main/actions";
import { getSampleBreadcrumbs } from "@/breadcrumbs";

@Component({
  components: {
    NavBar,
    NavDrawer,
  },
})
export default class SampleDetails extends Vue {
  coas: ICOA[] = [];
  loading = true;

  public formatDate(dateString: string) {
    return formatDate(dateString, null);
  }

  public async mounted() {
    const response = await callApi(this.$store, () => {
      return api.getMultiTestCOAs(this.$route.params.sampleId)
    });
    if (response?.data) {
      this.coas = response.data;
    }
  }

  get breadcrumbs() {
    return getSampleBreadcrumbs(this.$route.params.sampleId);
  }
}
